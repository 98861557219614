@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Pretendard";
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@layer components {
  ::selection {
    @apply text-white bg-primary;
  }

  /* ScrollBar [START] */
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    @apply bg-primary/30;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    @apply rounded-md bg-primary;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    @apply bg-primary/70;
  }
  /* ScrollBar [END] */
}

@font-face {
  font-family: "Pretendard";
  font-weight: 100;
  src: url("assets/fonts/Pretendard-Thin.woff");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 200;
  src: url("assets/fonts/Pretendard-ExtraLight.woff");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 300;
  src: url("assets/fonts/Pretendard-Light.woff");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 400;
  src: url("assets/fonts/Pretendard-Regular.woff");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 500;
  src: url("assets/fonts/Pretendard-Medium.woff");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 600;
  src: url("assets/fonts/Pretendard-SemiBold.woff");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 700;
  src: url("assets/fonts/Pretendard-Bold.woff");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 800;
  src: url("assets/fonts/Pretendard-ExtraBold.woff");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 900;
  src: url("assets/fonts/Pretendard-Black.woff");
}
